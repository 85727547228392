import { useQuery } from "@tanstack/react-query";

import { providersListAPI } from "@src/api/game-providers";

import { UseGameProvidersServiceOperators } from "@src/lib/types/game-providers";

const useGameProviders = (): Readonly<UseGameProvidersServiceOperators> => {
  const { data, isLoading } = useQuery({
    queryKey: ["game-providers"],
    queryFn: async () => {
      const result = await providersListAPI();
      return result;
    },
  });

  return {
    data,
    gameProviders: data?.items || [],
    isLoading,
  };
};

export default useGameProviders;
