import UploadIcon from "@mui/icons-material/CloudUploadOutlined";
import { Box, Button, Checkbox, CircularProgress, FormControl, FormControlLabel, FormGroup, Stack, Typography, useTheme } from '@mui/material';
import Config from "@src/utils/api/config";
import React from "react";
import { useInMailService } from "../hooks";
import Input from './input';
import { useCreateMailContext } from './provider';
import SelectItem from "./select-item";
import { styles } from './styles.css';

function MailAdvanceSettings() {
  const theme: any = useTheme();
  const boxRef = React.useRef<any>();
  const {products, games, uploadImage } = useInMailService();
  const { state, error, onDispatch } = useCreateMailContext();

  const onChangeActionButton = (type: string) => (value: boolean) => {
    if(type === "enableCoinBonus"){
      onDispatch("enableCoinBonus")(value);
      onDispatch("actionButtonType")("COIN_BONUS");
      onDispatch("enableCTA")(false);
    }else if(type === "enableCTA"){
      onDispatch("enableCTA")(value);
      onDispatch("actionButtonType")("CTA");
      onDispatch("enableCoinBonus")(false);
    }
  }

  const handleFileChange = (type: string) => (event: any) => {
    const file = event.target.files[0];
    if (file) {
      const formData = new FormData();
      formData.append("file", file);
      formData.append("type", type);
      uploadImage.mutate(formData);
    }
  };

  React.useEffect(() => {
    if(uploadImage.data && uploadImage.isSuccess){
      onDispatch(uploadImage.data.type)(`${Config.APP_CDN_URL}${uploadImage.data.result.filePath}`);   
    }
  },[uploadImage.isSuccess]);

  React.useEffect(() => {
    if(state.isActionButtonEnabled){
      boxRef.current?.scrollIntoView({ behavior: "smooth" })
    }
  },[state.isActionButtonEnabled]);

  if(!state.isActionButtonEnabled) return null;

  return (
    <Box ref={boxRef} sx={{p: 5, pr: 14}}>
      <Stack direction={"row"} sx={{gap: "0.2rem", display: "flex", alignItems: "center"}}>
        <FormControlLabel 
          label="ADD COIN BONUS"
          control={<Checkbox checked={state.enableCoinBonus} />} 
          onChange={(e: any) => onChangeActionButton("enableCoinBonus")(e.target.checked)}
          slotProps={{typography: {color: theme.palette.purple.dark, fontSize: 20, fontWeight: 700}}} />
        <Typography color="#B4B4B4" sx={{fontStyle: "italic"}}>
          Create a reward for your users. Enter coin amounts in your message
        </Typography>
      </Stack>
      <Stack direction={"row"} gap="2rem" ml={6}>
        <Input
          sx={{ width: 270 }}
          disabled={!state.enableCoinBonus}
          placeholder="Enter Amount"
          inputSx={{
            '& .MuiInputBase-root': {
              height: '50px',
            },
            }}
          label="Silver Coins:" 
          error={error.silverAmount}
          value={state.silverAmount}
          onChange={onDispatch("silverAmount")} />
        <Input
          sx={{ width: 270 }}
          disabled={!state.enableCoinBonus}
          placeholder="Enter Amount"
          inputSx={{
            '& .MuiInputBase-root': {
              height: '50px',
            },
            }}
          label="Bonus Gold Coins:" value={state.bonusGoldAmount}
          onChange={onDispatch("bonusGoldAmount")} />
      </Stack>
      <Stack direction={"row"} alignItems={"flex-end"} gap="2rem" ml={6}>
        <Input
          sx={{ width: 270 }}
          disabled={!state.enableCoinBonus}
          placeholder="CTA Name"
          inputSx={{
            '& .MuiInputBase-root': {
              height: '50px',
            },
            }}
          label="CTA Button:" value={state.ctaButtonName}
          onChange={onDispatch("ctaButtonName")} />

        <Box sx={styles.box_upload}>
          <input
            type="file"
            id="coinBonusButtonImage"
            accept="image/*"
            style={{ display: 'none' }}
            onChange={handleFileChange("coinBonusButtonImage")}
          />
          {uploadImage.isPending && <CircularProgress sx={{position: "absolute"}} />}
          {state.coinBonusButtonImage ?
            <img 
              src={state.coinBonusButtonImage} 
              alt="Selected" 
              style={{ maxWidth: '90%', height: '90%' }} /> :
            <>
            <UploadIcon />
            <Typography fontWeight={700}>Upload Button</Typography>
            </>}
        </Box>
      </Stack>
      <Stack direction={"row"} gap="2rem" ml={6} mt={2}>
        <Stack direction={"row"} gap="1rem" width={270} >
          <FormControl disabled={!state.enableCoinBonus} component="fieldset" variant="standard">
            <FormGroup sx={{flexDirection: "row"}}>
              <FormControlLabel label="Left"
                onChange={() => onDispatch("coinBonusButtonPosition")("LEFT")}
                control={<Checkbox checked={state.coinBonusButtonPosition === "LEFT"} />}  />
              <FormControlLabel label="Center"
                onChange={() => onDispatch("coinBonusButtonPosition")("CENTER")}
                control={<Checkbox checked={state.coinBonusButtonPosition === "CENTER"} />}/>
              <FormControlLabel label="Right"
                onChange={() => onDispatch("coinBonusButtonPosition")("RIGHT")}
                control={<Checkbox checked={state.coinBonusButtonPosition === "RIGHT"} />}/>
            </FormGroup>
          </FormControl>
        </Stack>
        <Stack direction={"row"} alignItems={"center"} justifyContent={"center"} gap="1rem" width={270} >
          <Button
            disabled={!state.enableCoinBonus}
            sx={{width: 101, bgcolor: "#484848", color: "white" }}
            onClick={() => {
              document.getElementById('coinBonusButtonImage')?.click();
            }} >UPLOAD</Button>
        </Stack>
      </Stack>

      <Stack direction={"row"} sx={{gap: "0.2rem", mt: 4, display: "flex", alignItems: "center"}}>
        <FormControlLabel
          label="ADD CTA" 
          control={<Checkbox checked={state.enableCTA} />} 
          onChange={(e: any) => onChangeActionButton("enableCTA")(e.target.checked)}
          slotProps={{typography: {color: theme.palette.purple.dark, fontSize: 20, fontWeight: 700}}} />
        <Typography color="#B4B4B4" sx={{fontStyle: "italic"}}>
          Select 1 action to apply to your message.
        </Typography>
      </Stack>
      <Stack direction={"row"} gap="0.5rem" ml={3}>
        <Checkbox sx={{mt: 5}}
          disabled={!state.enableCTA}
          checked={state.ctaType === "GAME"} 
          onChange={() => onDispatch("ctaType")("GAME")} />
        <SelectItem
          disabled={!state.enableCTA || state.ctaType !== "GAME"}
          options={games}
          formControlSx={{
            '& .MuiInputBase-root': {
              marginTop: "4px",
              width: "270px",
              height: '50px',
          }}}
          label="Connect to Game:"
          placeholder="Select Game"
          value={state.gameId}
          error={error.gameId}
          getOptionLabel={(option: any) => `${option.gameName}`}
          onChange={onDispatch("gameId")}  />
      </Stack>
      <Stack direction={"row"} gap="0.5rem" ml={3}>
        <Checkbox sx={{mt: 5}} 
          disabled={!state.enableCTA}
          checked={state.ctaType === "PRODUCT"} 
          onChange={() => onDispatch("ctaType")("PRODUCT")} />
        <SelectItem
          disabled={!state.enableCTA || state.ctaType !== "PRODUCT"}
          options={products}
          formControlSx={{
            '& .MuiInputBase-root': {
              marginTop: "4px",
              width: "270px",
              height: '50px',
          }}}
          label="Connect to Store Package:"
          placeholder="Select Store Package"
          value={state.productId}
          error={error.productId}
          getOptionLabel={(option: any) => `${option.description}`}
          onChange={onDispatch("productId")}  />
      </Stack>
      <Stack direction={"row"} gap="0.5rem" ml={3}>
        <Checkbox sx={{mt: 5}}
          disabled={!state.enableCTA}
          checked={state.ctaType === "URL"} 
          onChange={() => onDispatch("ctaType")("URL")} />
        <Input
          disabled={!state.enableCTA || state.ctaType !== "URL"}
          sx={{ width: 270 }}
          error={error.url}
          placeholder="Enter URL"
          inputSx={{
            '& .MuiInputBase-root': {
              height: '50px',
            },
            }}
          label="Connect to URL:" value={state.url}
          onChange={onDispatch("url")} />
      </Stack>
      <Stack direction={"row"} alignItems={"flex-end"} gap="2rem" ml={9.1}>
        <Input
          disabled={!state.enableCTA}
          sx={{ width: 270 }}
          placeholder="CTA Name"
          inputSx={{
            '& .MuiInputBase-root': {
              height: '50px',
            },
            }}
          label="CTA Button:" value={state.ctaButtonName}
          onChange={onDispatch("ctaButtonName")} />
        <Box sx={[styles.box_upload, error.ctaButtonImage && { border: "1px dashed red", color: "red" }]}>
          <input
            type="file"
            id="ctaButtonImage"
            accept="image/*"
            style={{ display: 'none' }}
            onChange={handleFileChange("ctaButtonImage")}
          />
          {uploadImage.isPending && <CircularProgress sx={{position: "absolute"}} />}
          {state.ctaButtonImage ?
            <img 
              src={state.ctaButtonImage} 
              alt="Selected" 
              style={{ maxWidth: '90%', height: '90%' }} /> :
            <>
            <UploadIcon />
            <Typography fontWeight={700}>Upload Button</Typography>
            </>}
        </Box>
      </Stack>
      <Stack direction={"row"} gap="2rem" ml={9.1} mt={2}>
        <Stack direction={"row"} gap="1rem" width={270} >
          <FormControl disabled={!state.enableCTA} component="fieldset" variant="standard">
            <FormGroup sx={{flexDirection: "row"}}>
              <FormControlLabel label="Left"
                onChange={() => onDispatch("ctaButtonPosition")("LEFT")}
                control={<Checkbox checked={state.ctaButtonPosition === "LEFT"} />}  />
              <FormControlLabel label="Center"
                onChange={() => onDispatch("ctaButtonPosition")("CENTER")}
                control={<Checkbox checked={state.ctaButtonPosition === "CENTER"} />}/>
              <FormControlLabel label="Right"
                onChange={() => onDispatch("ctaButtonPosition")("RIGHT")}
                control={<Checkbox checked={state.ctaButtonPosition === "RIGHT"} />}/>
            </FormGroup>
          </FormControl>
        </Stack>
        <Stack direction={"row"} alignItems={"center"} justifyContent={"center"} gap="1rem" width={270} >
          <Button disabled={!state.enableCTA} onClick={() => {
            document.getElementById('ctaButtonImage')?.click();
          }} sx={{width: 101, bgcolor: "#484848", color: "white" }} >UPLOAD</Button>
        </Stack>
      </Stack>
    </Box>
  );
}

export default MailAdvanceSettings;