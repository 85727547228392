import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import _capitalize from "lodash/capitalize";
import _reduce from "lodash/reduce";
import _unionBy from "lodash/unionBy";
import numeral from "numeral";
import { useSearchParams } from "react-router-dom";

import {
  allUsersAPI,
  exportUsersListAPI,
  getActiveUsersAPI,
  getUsersDemographicBy,
  totalActiveUsersAPI,
  totalUsersAPI,
  updatePasswordAPI,
  updateUserBalanceAPI,
  updateUserDetailsAPI,
  updateUserTierLevelAPI,
} from "@src/api/users";

import { useToasterContext } from "@src/context/Toaster";

import {
  GetActiveUsersAPIParams,
  GetAllUsersParams,
  UpdateUserPasswordAPIParams,
  UseUsersServiceOperators,
  UseUsersServiceParams,
  UserBalanceListType,
  UserObjectType,
  UsersAPIREquest,
  UsersDemographicRequestType,
} from "@src/lib/types/users";

import { getCurrentMonthDays } from "@src/utils/current-month";

import {
  getAmountBalanceByCoinType,
  transformUsersWithBalance,
} from "@src/utils/filter-helper";

import { downloadExcel } from "@src/utils/download-helper";

import { useUsersContext } from "./provider";
import { AlertType } from "@src/lib/types/toaster";
import { useAuthService } from "@src/store/hooks";
import { USERTYPES } from "@src/lib/constants";

export const useUsersService = (
  args?: UseUsersServiceParams
): Readonly<UseUsersServiceOperators> => {
  const queryClient = useQueryClient();

  const {
    listQueryKey = "users",
    fetchList = false,
    fetchDemographics = false,
    fetchTotalUsers = false,
    fetchTotalUsersThisMonth = false,
    fetchTotalActiveUsers = false,
  } = args || {};

  const [params] = useSearchParams();
  const userId = params.get("u");
  const onlyActiveUsers = params.get('activeUsers') === 'true';

  const {
    state,
    handlePageChange,
    handleCloseMoreDetails,
    handleCloseUpdateBalance,
    handleCloseUpdateTierLevel,
  } = useUsersContext();

  const { triggerOpen } = useToasterContext();

  const { currentUser } = useAuthService();

  const {
    page,
    size = 10,
    debouncedSearch = "",
    fromDate,
    toDate,
    selectedDemographicCategory,
    order,
    orderBy,
  } = state ?? {};
  const newPage = (page || 0) + 1;

  const usersListQueryKey = [
    listQueryKey,
    {
      size,
      debouncedSearch,
      newPage,
      fromDate,
      toDate,
      userId,
      order,
      orderBy,
      userTypeIDs: [USERTYPES.PLAYER]
    },
  ];

  const activeUsersListQueryKey = [
    'active-users',
    {
      size,
      debouncedSearch,
      newPage,
      fromDate,
      toDate,
      order,
      orderBy,
    },
  ]

  const { data, isLoading, isRefetching, refetch } = useQuery({
    queryKey: usersListQueryKey,
    queryFn: async () => {
      const reqParams: GetAllUsersParams = {
        size,
        page: newPage,
        userTypeIDs: [USERTYPES.PLAYER]
      };

      if (userId) reqParams.userID = Number(userId);

      if (fromDate) reqParams.fromDate = fromDate;
      if (toDate) reqParams.toDate = toDate;

      if (debouncedSearch) reqParams.search = debouncedSearch;

      if (orderBy && order) reqParams.sort = `${orderBy},${order}`;

      const usersRes = await allUsersAPI(reqParams);

      const usersWithBalance = await transformUsersWithBalance(usersRes.items);

      usersRes.items = usersWithBalance;

      return usersRes;
    },
    enabled: fetchList,
  });

  const { data: activeUsers, isLoading: activeUsersLoading, isRefetching: activeUsersRefetching, refetch: activeUsersRefetch } = useQuery({
    queryKey: activeUsersListQueryKey,
    queryFn: async () => {
      const reqParams: GetActiveUsersAPIParams = {
        size,
        page: newPage,
      };

      if (fromDate) reqParams.fromDate = fromDate;
      if (toDate) reqParams.toDate = toDate;
      if (debouncedSearch) reqParams.search = debouncedSearch;

      if (orderBy && order) reqParams.sort = `${orderBy},${order}`;

      const usersRes = await getActiveUsersAPI(reqParams);

      return usersRes;
    },
    enabled: onlyActiveUsers,
  })

  const { data: demographicsData } = useQuery({
    queryKey: ["user-demographics", selectedDemographicCategory],
    queryFn: async () => {
      const usersRes = await getUsersDemographicBy(
        selectedDemographicCategory as UsersDemographicRequestType
      );
      return usersRes;
    },
    select: ({ data }) => {
      return data?.groups?.map((group: any) => {
        if (selectedDemographicCategory === "age") {
          group.name = group.ageRange.split("_").filter(Boolean).join(" - ");
        }

        if (selectedDemographicCategory === "gender") {
          group.name = _capitalize(group.genderName);
        }

        if (selectedDemographicCategory === "country") {
          group.name = _capitalize(group.countryName);
        }

        return group;
      });
    },
    enabled: fetchDemographics,
  });

  const { data: totalUsers } = useQuery({
    queryKey: ["total-users"],
    queryFn: async () => {
      const usersRes = await totalUsersAPI({
        userTypeID: USERTYPES.PLAYER
      });
      return usersRes;
    },
    enabled: fetchTotalUsers,
  });

  const { data: totalActiveUsers } = useQuery({
    queryKey: ["total-active-users"],
    queryFn: async () => {
      const usersRes = await totalActiveUsersAPI();
      console.log("usersRes", usersRes)
      return usersRes;
    },
    enabled: fetchTotalActiveUsers,
  });

  const { data: totalUsersThisMonth } = useQuery({
    queryKey: ["total-users-this-month"],
    queryFn: async () => {
      const { fromDate, toDate } = getCurrentMonthDays();

      const gamesRes = await totalUsersAPI({
        fromDate,
        toDate,
      });
      return gamesRes;
    },
    enabled: fetchTotalUsersThisMonth,
  });

  const onRefreshUsers = () => {
    handlePageChange(null, 0);
    refetch();
  };

  const onRefreshActiveUsers = () => {
    handlePageChange(null, 0);
    activeUsersRefetch();
  };

  const _updateUserItemFromList = (newUserData: UserObjectType) => {
    queryClient.setQueryData(usersListQueryKey, (usersRes: UsersAPIREquest) => {
      const newItems = usersRes?.items?.map((user: UserObjectType) => {
        if (user.id === newUserData.id) {
          return {
            ...user,
            ...newUserData,
          };
        }

        return user;
      });

      return {
        ...usersRes,
        items: newItems,
      };
    });
  };

  const _updateUserBalanceFromList = (newBalanceItem: UserBalanceListType) => {
    queryClient.setQueryData(usersListQueryKey, (usersRes: UsersAPIREquest) => {
      const newItems = usersRes?.items?.map((user: UserObjectType) => {
        if (user.id === state.selectedUser?.id) {
          return {
            ...user,
            userBalanceList: _unionBy(
              [newBalanceItem],
              user.userBalanceList,
              "id"
            ),
          };
        }

        return user;
      });

      return {
        ...usersRes,
        items: newItems,
      };
    });
  };

  const onUpdateUserDetails = useMutation({
    mutationKey: ["update-user-details"],
    mutationFn: updateUserDetailsAPI,
    onSuccess: async ({ data: { data: updatedUser } }) => {
      _updateUserItemFromList(updatedUser);

      if (updatedUser.id === currentUser.id) queryClient.setQueryData(["current-user"], updatedUser);

      triggerOpen({
        message: "Successfully updated user details.",
      });

      handleCloseMoreDetails();
    },
  });

  const onUpdateUserBalance = useMutation({
    mutationKey: ["update-user-balance"],
    mutationFn: updateUserBalanceAPI,
    onSuccess: ({ data }) => {
      _updateUserBalanceFromList(data.data);

      triggerOpen({
        message: "Successfully updated user balance.",
      });

      handleCloseUpdateBalance();
    },
    onError: (error) => {
      triggerOpen({
        type: AlertType.error,
        title: "Failed to update user balance.",
        message: error.message
      });
    },
  });

  const onUpdateUserTierLevel = useMutation({
    mutationKey: ["update-user-tier-level"],
    mutationFn: updateUserTierLevelAPI,
    onSuccess: ({ data }) => {
      _updateUserItemFromList(data.data);

      triggerOpen({
        message: "Successfully updated user tier level.",
      });

      handleCloseUpdateTierLevel();
    },
    onError: (error) => {
      triggerOpen({
        type: AlertType.error,
        title: "Failed to update user tier level.",
        message: error.message
      });
    },
  });

  const onUpdateUserPassword = useMutation({
    mutationKey: ["update-user-password"],
    mutationFn: async (params: UpdateUserPasswordAPIParams) => {
      const result = await updatePasswordAPI(params);
      return result;
    },
    onSuccess: ({ data }) => {
      triggerOpen({
        message: "Successfully updated password.",
      });
    },
    onError: (error: any) => {
      if (error?.response?.data?.error?.message !== "Incorrect current password") {
        triggerOpen({
          type: AlertType.error,
          title: "Failed to update password.",
          message: error?.response?.data?.error?.message
        });
      }
    },
  });

  const onExportUsersList = useMutation({
    mutationKey: ["export-users-list"],
    mutationFn: () => {
      const reqParams: GetAllUsersParams = {
        userTypeIDs: [USERTYPES.PLAYER]
      };

      if (userId) reqParams.userID = Number(userId);

      if (fromDate) reqParams.fromDate = fromDate;
      if (toDate) reqParams.toDate = toDate;

      if (debouncedSearch) reqParams.search = debouncedSearch;

      if (orderBy && order) reqParams.sort = `${orderBy},${order}`;

      return exportUsersListAPI(reqParams);
    },
    onSuccess: ({ data }) => {
      const newJson = _reduce(
        data.items,
        (result: any, value: any, key) => {
          const coins = getAmountBalanceByCoinType(value.userBalanceList || []);

          result.push({
            id: value.id,
            "First Name": value.firstName,
            "Last Name": value.lastName,
            Email: value.emailAddress,
            "Display Name": value.displayName,
            "Silver Balance": numeral(coins.SILVER).format("0,000"),
            "GWz Gold": numeral(coins.GOLD).format("0,000.00"),
            "Gold Balance": numeral(coins["GOLD BONUS"]).format("0,000.00"),
            CreatedAt: value.enrollmentDate,
            Status: value.isDeleted ? "Deactivated" : "Active",
          });

          return result;
        },
        []
      );

      downloadExcel(newJson, "export-user");
    },
  });

  return {
    data,
    demographicsData: demographicsData || [],
    users: data?.items,
    totalRecords: data?.totalRecords || 0,
    totalPages: data?.totalPages || 0,
    totalUsers,
    totalUsersThisMonth,
    totalActiveUsers,
    isLoading,
    isRefreshing: isRefetching,
    activeUsersData: activeUsers,
    activeUsers: activeUsers?.items || [],
    activeUsersLoading,
    activeUsersRefetching,
    onRefreshActiveUsers,
    onRefreshUsers,
    onUpdateUserDetails,
    onUpdateUserBalance,
    onUpdateUserTierLevel,
    onExportUsersList,
    onUpdateUserPassword,
  };
};
