import axios, {
  AxiosRequestConfig,
  AxiosResponse,
  InternalAxiosRequestConfig,
} from "axios";

import Config from "./api/config";

import * as TokenHelper from "./token-helper";
import toasterEventEmitter, { TOASTER_EVENT } from "./event-emitter";

const exceptedRoutes = ['/user/portal/authenticate', '/user/create-portal-user/'];

const api = axios.create({
  baseURL: `${Config.API_BASE_URL}/api/v1`,
  headers: {
    Accept: "application/json",
    "Content-Type": "application/json",
    timeout: 1000 * 10,
  },
});

api.interceptors.request.use(
  async (config: InternalAxiosRequestConfig) => {
    const accessToken = await TokenHelper.getAccessToken();
    if (accessToken) {
      config.headers.Authorization = `Bearer ${accessToken}`;
    }
    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);

api.interceptors.response.use(
  async (response: AxiosResponse) => response,
  async (error) => {
    const originalRequest: AxiosRequestConfig & {
      _retry: boolean;
    } = error.config;

    if (
      error.response?.status === 401 &&
      !originalRequest._retry &&
      !exceptedRoutes.includes(originalRequest.url || '')
    ) {
      originalRequest._retry = true;

      try {
        const { data } = await axios.post(
          `${Config.API_BASE_URL}/api/v1/user/portal/refresh-token`,
          {
            refreshToken: TokenHelper.getRefreshToken(),
          }
        );

        TokenHelper.setAccessToken(data.accessToken);
        TokenHelper.setRefreshToken(data.refresToken);

        api.defaults.headers.common[
          "Authorization"
        ] = `Bearer ${data.accessToken}`;

        return api(originalRequest);
      } catch (refreshError: any) {
        toasterEventEmitter.emit(TOASTER_EVENT, {
          type: 'error',
          title: 'Session Expired',
          message: 'Please login again.',
        });

        setTimeout(() => {
          window.location.replace("/login")
        }, 3000);

        throw refreshError;
      }
    }

    return Promise.reject(error);
  }
);

export default api;
