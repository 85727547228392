import axiosClient from "@src/utils/axios-client";

export const providersListAPI = async () => {
  try {
    const result = await axiosClient.get("/provider");
    return result.data || {};
  } catch (error: any) {
    throw error.response.data.error;
  }
};
