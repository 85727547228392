import { Helmet } from 'react-helmet-async';

import RedeemProvider from './provider';
import Body from './body';
import UpdateRedeemStatus from './update-redeem-status';
import RedeemTransactionsFilterSidebar from './filter';


export default function RedeemPage() {
  return (
    <RedeemProvider>
      <>
        <Helmet>
          <title> Redeem Transactions </title>
        </Helmet>
        <Body />
        <UpdateRedeemStatus />
        <RedeemTransactionsFilterSidebar />
      </>
    </RedeemProvider>
  );
}
