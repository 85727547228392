import React from 'react'
import { Card, Container, Typography } from '@mui/material'

import { useProgramsService } from './hooks'
import FilterSection from './filter-section'
import DataSection from './data-section'

type Props = {}

const Body = (props: Props) => {
    useProgramsService();

    return (
        <Container maxWidth="xl">
            <Typography variant="h4" sx={{ mb: 5 }}>Revenue Program</Typography>
            <Card sx={{ p: 1, minHeight: 500 }}>
                <FilterSection />
                <DataSection />
            </Card>
        </Container>
    )
}

export default Body;