import {
  TableRow,
  TableCell,
  IconButton,
} from '@mui/material';
import moment from 'moment';
import numeral from 'numeral';

import Iconify from '@src/components/iconify/Iconify';

import { redeemStatusName, redeemTypeName } from '@src/utils/filter-helper';

import Config from '@src/utils/api/config';

const { SITE } = Config;

const TableRowItem = (props: any) => {
  if (SITE === 'palmsplay') {
    return (
      <TableRow hover role="checkbox">
        <TableCell align="inherit">{props.userID}</TableCell>
        <TableCell align="inherit">{props.user?.displayName || '-'}</TableCell>
        <TableCell align="inherit">{numeral(props.goldAmount).format("0,000.00")}</TableCell>
        <TableCell align="left">{moment(props.requestDate).format("MM-DD-YYYY hh:mm A")}</TableCell>
        <TableCell align="inherit">{redeemStatusName(props.redeemStatusID)}</TableCell>
        <TableCell align="right">
          <IconButton size="large" color="inherit" onClick={props.handleOpenMenu}>
            <Iconify icon={'eva:more-vertical-fill'} />
          </IconButton>
        </TableCell>
      </TableRow>
    );
  }

  return (
    <TableRow hover role="checkbox">
      <TableCell align="inherit">{props.id}</TableCell>
      <TableCell align="inherit">{props.userID}</TableCell>
      <TableCell align="inherit">{redeemTypeName(props.redeemTypeID)}</TableCell>
      <TableCell align="inherit">{redeemStatusName(props.redeemStatusID)}</TableCell>
      <TableCell align="inherit">{numeral(props.goldAmount).format("0,000.00")}</TableCell>
      <TableCell align="left">{moment(props.requestDate).format("MM-DD-YYYY hh:mm A")}</TableCell>
      <TableCell align="right">
        <IconButton size="large" color="inherit" onClick={props.handleOpenMenu}>
          <Iconify icon={'eva:more-vertical-fill'} />
        </IconButton>
      </TableCell>
    </TableRow>
  );
}

export default TableRowItem;
