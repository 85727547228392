import PropTypes from 'prop-types';
import React from 'react';
// @mui
import { AppBar, Box, IconButton, Stack, Toolbar, Typography } from '@mui/material';
import { styled } from '@mui/material/styles';

// components
import Iconify from '../../../components/iconify';
//
// import AccountPopover from './AccountPopover';

import { FullAccess } from '@src/utils/drawer-list';
import { findTitleByPath } from '@src/utils/filter-helper';
import { useLocation } from 'react-router-dom';
import HeaderProvider from './provider';

// ----------------------------------------------------------------------

const NAV_WIDTH = 280;

const HEADER_MOBILE = 64;

const HEADER_DESKTOP = 92;

const StyledRoot = styled(AppBar)(({ theme }): any => ({
  boxShadow: 'none',
  backgroundColor: "white",
  [theme.breakpoints.up('lg')]: {
    width: `calc(100% - ${NAV_WIDTH + 1}px)`,
  },
}));

const StyledToolbar = styled(Toolbar)(({ theme }) => ({
  minHeight: HEADER_MOBILE,
  [theme.breakpoints.up('lg')]: {
    minHeight: HEADER_DESKTOP,
    padding: theme.spacing(0, 5),
  },
}));

// ----------------------------------------------------------------------

Header.propTypes = {
  onOpenNav: PropTypes.func,
};

export default function Header({ onOpenNav }: any) {
  const location = useLocation();
  const title = React.useMemo(() => {
    return findTitleByPath(FullAccess, location.pathname);
  }, [location]);

  return (
    <HeaderProvider>
      <StyledRoot>
        <StyledToolbar>
          <Stack
            direction="row"
            alignItems="center"
            spacing={{
              xs: 0.5,
              sm: 1,
            }} >
            <Typography variant="h4" sx={{ color: "#343C6A", textTransform: 'capitalize' }}>
              {title}
            </Typography>
          </Stack>
          <Box sx={{ flexGrow: 1 }} />
          <IconButton
            onClick={onOpenNav}
            sx={{
              mr: 1,
              color: 'text.primary',
              display: { lg: 'none' },
            }}
          >
            <Iconify icon="eva:menu-2-fill" />
          </IconButton>
        </StyledToolbar>
      </StyledRoot>
    </HeaderProvider>
  );
}
