import {
    Card,
    Container,
    IconButton,
    Stack,
    Table,
    TableContainer,
    TablePagination,
    Typography,
} from '@mui/material';
import { useNavigate } from 'react-router-dom';

import DataTableRow from '@src/components/data-table/data-row';
import TableHeader from '@src/components/data-table/header';
import Scrollbar from '@src/components/scrollbar';
import Iconify from '@src/components/iconify/Iconify';

import { TABLE_HEAD_ACTIVE_USERS } from '@src/utils/mockdata/table-headers';

import { useUsersContext } from '../provider';
import Toolbar from './toolbar';
import { useUsersService } from '../hooks';
import TableRowItem from './table-row';

const ActiveUsersBody = () => {
    const navigate = useNavigate();

    const {
        state,
        handleRequestSort,
        handlePageChange,
        handleRowsPerPageChange
    } = useUsersContext();

    const {
        activeUsers,
        activeUsersData,
        activeUsersLoading,
        activeUsersRefetching,
        onRefreshActiveUsers
    } = useUsersService();

    return (
        <Container>
            <Stack direction="row" alignItems="center">
                <IconButton size="large" color="default" onClick={() => navigate('/dashboard/users')}>
                    <Iconify icon={'mdi:arrow-left'} width={30} />
                </IconButton>
                <Typography variant="h4" gutterBottom>
                    Active Sessions (monthly)
                </Typography>
            </Stack> 

            <Toolbar
                handleRefresh={onRefreshActiveUsers}
            />

            <Card sx={{ borderRadius: "25px", px: "23px", py: "10px" }}>
                <Scrollbar>
                    <TableContainer sx={{ minWidth: 800 }}>
                        <Table>
                            <TableHeader
                                order={state.order}
                                orderBy={state.orderBy}
                                headLabel={TABLE_HEAD_ACTIVE_USERS}
                                onRequestSort={handleRequestSort}
                            />

                            <DataTableRow
                                colSpan={9}
                                isLoading={activeUsersLoading}
                                isRefreshing={activeUsersRefetching}
                                headers={TABLE_HEAD_ACTIVE_USERS}
                                filterName={state.filterName}
                                data={activeUsers || []}
                                renderItem={({ item, index }: any) => {
                                    return (
                                        <TableRowItem
                                            key={`${index}`}
                                            {...item}
                                        />
                                    )
                                }}
                            />
                        </Table>
                    </TableContainer>
                </Scrollbar>

                <TablePagination
                    rowsPerPageOptions={[10, 25, 50]}
                    page={(state.page || 0)}
                    component="div"
                    rowsPerPage={state.size || 10}
                    count={activeUsersData?.totalRecords ?? 0}
                    onPageChange={handlePageChange}
                    onRowsPerPageChange={handleRowsPerPageChange}
                />
            </Card>
        </Container>
    )
}

export default ActiveUsersBody