import { IconButton, InputAdornment } from "@mui/material";
import ButtonExport from "@src/components/buttons/button-export";
import Iconify from "@src/components/iconify";
import { UsersToolbarProps } from "@src/lib/types/users";
import { StyledRoot, StyledSearch, StyledWrap } from "@src/sections/@dashboard/styles.css";

import { useUsersContext } from "../provider";

const Toolbar = ({
    isLoading,
    handleRefresh
}: UsersToolbarProps) => {
    const {
        state,
        accessControl,
        handleSearch,
        // handleOpenFilterSidebar
    } = useUsersContext();

    const { search } = state ?? {}

    return (
        <StyledRoot>
            <StyledSearch
                disabled={isLoading}
                value={search}
                onChange={(event) => handleSearch(event.target.value)}
                placeholder="Search.."
                startAdornment={
                    <InputAdornment position="start">
                        <Iconify
                            icon="eva:search-fill"
                            sx={{
                                color: 'text.disabled',
                                width: 20,
                                height: 20
                            }}
                        />
                    </InputAdornment>
                }
            />

            <StyledWrap>
                {/* <Button
                    disableRipple
                    color="inherit"
                    endIcon={<Iconify icon="ic:round-filter-list" />}
                    onClick={handleOpenFilterSidebar}
                >
                    Filters&nbsp;
                </Button> */}
                <IconButton aria-label="delete" color="primary" onClick={handleRefresh}>
                    <Iconify icon={'mdi:refresh'} width={30} />
                </IconButton>
                {accessControl?.allowExportUserData && <ButtonExport
                    sx={{
                        marginLeft: 5, px: 5,
                        bgcolor: "purple.dark",
                        "&:hover": {
                            bgcolor: "purple.main"
                        }
                    }}
                // onClick={() => onExportUsersList.mutate({})}
                // loading={onExportUsersList.isPending}
                />}
            </StyledWrap>
        </StyledRoot>
    )
}

export default Toolbar