import Paper from '@mui/material/Paper';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';

import { useUsersService } from './hooks';

type RowItemType = {
    name: string;
    numUsers: number;
    numNewUsers: number;
}

const getTop10CountriesByUsers = (countriesArray: RowItemType[]) => {
    return countriesArray
        .sort((a, b) => b.numUsers - a.numUsers)
        .slice(0, 10);
};

export default function DemographicsCountryTable() {
    const { demographicsData } = useUsersService({
        fetchDemographics: true,
    });

    const sortedCountries = getTop10CountriesByUsers(demographicsData);

    return (
        <TableContainer component={Paper} >
            <Table sx={{ minWidth: 650 }} size="small" aria-label="a dense table">
                <TableHead>
                    <TableRow>
                        <TableCell>Country</TableCell>
                        <TableCell align="right">Total No. Users</TableCell>
                        <TableCell align="right">Total New Users</TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    {sortedCountries?.map((row: RowItemType) => (
                        <TableRow
                            key={row.name}
                            sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                        >
                            <TableCell component="th" scope="row">
                                {row.name}
                            </TableCell>
                            <TableCell align="right">{row.numUsers}</TableCell>
                            <TableCell align="right">{row.numNewUsers}</TableCell>
                        </TableRow>
                    ))}
                </TableBody>
            </Table>
        </TableContainer>
    );
}
