import { Box, Grid } from "@mui/material";
import { AppWidgetSummary } from "@src/sections/@dashboard/app";
import palette from '@src/theme/palette';
import { usePlatformToolsServices } from "./hooks";
import Config from "@src/utils/api/config";

const { isPalmsPlay } = Config;

const Header = () => {
	const {
		mostPopularDeal,
		mostPopularGoldShopItem,
		mostPopularShopItem,
		totalMissionsCompleted,
		totalSubscriptions
	} = usePlatformToolsServices();

	return (
		<>
			<Box display="flex" flexDirection="row" gap={3}>
				<Grid container spacing={2.5}>
					<Grid item xs={6} sm={6} md={3} >
						<AppWidgetSummary
							disabled
							title="Missions Completed"
							value={totalMissionsCompleted}
							color={palette.blue.light}
							icon={'mdi:sword-cross'}
						/>
					</Grid>
						
					<Grid item xs={6} sm={6} md={3} >
						<AppWidgetSummary
							disabled
							title="Most Popular Deal"
							value={mostPopularDeal?.description}
							color={palette.peach.darker}
							icon={'mdi:fire'}
							valueProps={{
								variant: "h5",
							}}
						/>
					</Grid>

					{!isPalmsPlay && <Grid item xs={6} sm={6} md={3} >
						<AppWidgetSummary
							disabled
							title="Popular Gold Shop Item"
							value={mostPopularGoldShopItem?.description}
							color={palette.orangeYellow.main}
							icon={'mdi:cart'}
							valueProps={{
								variant: "h5",
							}}
						/>
					</Grid>}

					<Grid item xs={6} sm={6} md={3}>
						<AppWidgetSummary
							disabled
							title="Popular Shop Item"
							color={palette.DarkTurquoise.light}
							value={mostPopularShopItem?.description}
							icon={'mdi:cart'}
							valueProps={{
								variant: "h5",
							}}
						/>
					</Grid>
				</Grid>
			</Box>
		</>
	)
}

export default Header