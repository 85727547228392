import { Box, Button, ButtonGroup, Container } from "@mui/material";
import { Bar, BarChart, CartesianGrid, ResponsiveContainer, Tooltip, XAxis, YAxis } from 'recharts';

import Iconify from "@src/components/iconify";
import { useState } from "react";
import DemographicsCountryTable from "./demographics-country-table";
import { useUsersService } from "./hooks";
import { useUsersContext } from "./provider";

const WrappedUserDemographics = () => {
	const { demographicsData } = useUsersService({ fetchDemographics: true });
	const { state, onDispatch } = useUsersContext();
	const { showDemographics, selectedDemographicCategory } = state || {};

	const [activeTab, setActiveTab] = useState(1);
	const [countryChartType, setCountryChartType] = useState(1);

	const handleSwitchActiveTab = (newActive: number) => {
		setCountryChartType(1);
		setActiveTab(newActive);

		const categoryObject: any = {
			1: 'age',
			2: 'gender',
			3: 'country',
		}
		onDispatch('selectedDemographicCategory')(categoryObject[newActive]);
	}

	const getButtonColor = (isActive: boolean) => {
		if (isActive) return 'theme.primary';
		return '#ccc';
	}

	if (!showDemographics) return null;

	return (
		<Container sx={{ marginTop: 5 }}>
			<Box mb={2}>
				<ButtonGroup variant="contained" aria-label="Basic button group">
					<Button
						onClick={() => handleSwitchActiveTab(1)}
						sx={{ background: getButtonColor(activeTab === 1) }}
					>
							Age
					</Button>
					<Button
						onClick={() => handleSwitchActiveTab(2)}
						sx={{ background: getButtonColor(activeTab === 2) }}
					>
						Gender
					</Button>
					<Button
						onClick={() => handleSwitchActiveTab(3)}
						sx={{ background: getButtonColor(activeTab === 3) }}
					>
						Country
					</Button>
				</ButtonGroup>
			</Box>

			{selectedDemographicCategory === 'country' && (
				<Box mb={2}>
					<ButtonGroup variant="contained" aria-label="Basic button group">
						<Button
							onClick={() => setCountryChartType(1)}
							sx={{ background: getButtonColor(countryChartType === 1) }}
						>
							<Iconify
								icon="fa-solid:chart-area"
								width={16}
								sx={{
										opacity: 1,
										color: 'common.white',
								}}
							/>
						</Button>
						<Button
								onClick={() => setCountryChartType(2)}
								sx={{ background: getButtonColor(countryChartType === 2) }}
						>
								<Iconify
										icon="fa-solid:table"
										width={16}
										sx={{
												opacity: 1,
												color: 'common.white',
										}}
								/>
						</Button>
					</ButtonGroup>
				</Box>
			)}

			{activeTab === 3 && countryChartType === 2 ? (
				<DemographicsCountryTable />
			) : (
				<Box p={"20px"} borderRadius={"25px"} display={"flex"} bgcolor={"white"}
					width={"100%"}
					height={350} >
					<ResponsiveContainer width="100%" height="100%">
						<BarChart
							data={demographicsData}
							margin={{
								top: 20,
								right: 20,
								bottom: 20,
								left: 20,
							}}
						>
							<CartesianGrid strokeDasharray="3 3" />
							{selectedDemographicCategory === 'country' ? (
								<XAxis
									dataKey="name"
									angle={-45}
									textAnchor="end"
									height={70}
									dy={10}
								/>
							) : (
								<XAxis dataKey="name" />
							)}
							<YAxis label={{ value: 'Total number of users', angle: -90, position: 'insideLeft', dy: 80, dx: -20 }} />
							<Tooltip />
							<Bar
								dataKey="numUsers"
								fill="#8884d8"
							/>
						</BarChart>
					</ResponsiveContainer>
				</Box>
			)}
		</Container>
	)
}

const UserDemographics = () => {
	const { accessControl } = useUsersContext();
	const { allowDemoGraphs } = accessControl || {};

	if (!allowDemoGraphs) return null;

	return <WrappedUserDemographics />
}

export default UserDemographics