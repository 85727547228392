/* eslint-disable react-hooks/exhaustive-deps */
import React, { createContext, useContext } from 'react';

import { useInputHelper } from '@src/utils/inputs-helper';
import { GenerateProgramsAPIParams, ProgramsContextStateType, ProgramsContextValueType, ProgramsProviderProps } from '@src/lib/types/programs';

const initialState: ProgramsContextStateType = {
  from_date: null,
  to_date: null,
  providerName: "Evo",
};

export const ProgramContext = createContext<ProgramsContextValueType>({} as ProgramsContextValueType);

const ProgramProvider = ({ children }: ProgramsProviderProps) => {
  const { state, onDispatch } = useInputHelper(initialState);

  const onSetFormValues = (args: GenerateProgramsAPIParams) => {
    const { providerName, from_date, to_date } = args || {};
    onDispatch('providerName')(providerName);
    onDispatch('from_date')(from_date);
    onDispatch('to_date')(to_date);
  };

  const value = {
    state,
    onDispatch,
    onSetFormValues,
  };

  return (
    <ProgramContext.Provider value={value}>
      {children}
    </ProgramContext.Provider>
  );
};

export const useProgramsContext = () => {
  return useContext(ProgramContext);
}

export default ProgramProvider;
