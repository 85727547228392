// @mui
import { Box, ButtonBase, Card, Typography } from '@mui/material';
import { alpha, styled } from '@mui/material/styles';
// components
import Iconify from '../../../components/iconify';

// ----------------------------------------------------------------------

const StyledIcon = styled('div')(({ theme }) => ({
  margin: 'auto',
  display: 'flex',
  borderRadius: '50%',
  alignItems: 'center',
  width: theme.spacing(7.2),
  height: theme.spacing(7.2),
  justifyContent: 'center',
}));

type AppProps = {
  color?: string;
  icon?: React.ReactNode | string;
  title: string;
  value?: string | number;
  sx?: any;
  noAmount?: boolean;
  labelProps?: any;
  valueProps?: any;
  disabled?: boolean;
  onClick?: (args?: any) => void;
}

export default function AppWidgetSummary({ title, value, icon, color = '#111111',
  sx, labelProps, valueProps, noAmount, disabled, onClick }: AppProps) {

  return (
    <Card sx={{
      pl: "20px",
      pr: "20px",
      boxShadow: 0,
      display: "flex",
      alignItems: "center",
      flexDirection: "row",
      textAlign: 'center',
      height: "120px",
      bgcolor: "white",
      borderRadius: "25px",
      ...sx,
    }}
    >
      <ButtonBase disabled={disabled} disableRipple onClick={onClick} sx={{gap: "0.7rem"}}>
        <StyledIcon
          sx={{
            color,
            backgroundImage: () =>
              `linear-gradient(135deg, ${alpha(color, 0)} 0%, ${alpha(
                color,
                0.24
              )} 100%)`,
          }}
        >
          {typeof icon === 'string' ? <Iconify icon={icon} width={24} height={24} /> : icon}
        </StyledIcon>
        <Box>
        <Typography variant="subtitle2"
          color="#718EBF"
          fontFamily={"Inter"}
          fontSize={"12px"}
          textAlign={"left"}
          {...labelProps} >
          {title}
        </Typography>
        {noAmount ? null : <Typography 
          variant="h4" 
          fontFamily={"Inter"}
          textAlign={"left"}
          fontSize={"25px"}
          {...valueProps} >
          {value}
        </Typography>}
        
        </Box>
      </ButtonBase>
    </Card>
  );
}
