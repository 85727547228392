import axiosClient from "@src/utils/axios-client";

export const mostPopularDealAPI = async () => {
  try {
    const result = await axiosClient.get("/platform/deals/most-popular");
    return result?.data;
  } catch (error) {
    throw error;
  }
};

export const mostPopularGoldShopItemAPI = async () => {
  try {
    const result = await axiosClient.get(
      "/platform/gold-shop-items/most-popular"
    );
    return result?.data;
  } catch (error) {
    throw error;
  }
};

export const mostPopularShopItemAPI = async () => {
  try {
    const result = await axiosClient.get("/platform/shop-items/most-popular");
    return result?.data;
  } catch (error) {
    throw error;
  }
};

export const totalSubscriptionsCountAPI = async () => {
  try {
    const result = await axiosClient.get(
      "/platform/subscriptions/purchased/count"
    );
    return result?.data?.count || 0;
  } catch (error) {
    throw error;
  }
};

export const totalCompletedMissionsCountAPI = async () => {
  try {
    const result = await axiosClient.get(
      "/platform/missions/reward-claimed/count"
    );
    return result?.data?.count || 0;
  } catch (error) {
    throw error;
  }
};
