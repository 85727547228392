import React from 'react';
import { Controller, useForm } from 'react-hook-form';
import { Box } from '@mui/material';
import { LoadingButton } from '@mui/lab';
import moment from 'moment';
import { LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { MobileDatePicker } from '@mui/x-date-pickers/MobileDatePicker';

import { GenerateProgramsAPIParams } from '@src/lib/types/programs';

import { useProgramsService } from './hooks';
import ProviderName from './provider-name';
import { useProgramsContext } from './provider';

const FilterSection = () => {
  const { handleSubmit, control } = useForm<GenerateProgramsAPIParams>();

  const { onSetFormValues } = useProgramsContext();
  const { isLoading } = useProgramsService();

  const onSubmit = (data: GenerateProgramsAPIParams) => {
    onSetFormValues({
      providerName: data.providerName,
      from_date: data.from_date ? moment(data.from_date).format("YYYY-MM-DD") : null,
      to_date: data.to_date ? moment(data.to_date).format("YYYY-MM-DD") : null,
    });
  }

  return (
    <LocalizationProvider dateAdapter={AdapterDateFns}>
      <form onSubmit={handleSubmit(onSubmit)}>
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'row',
            alignItems: 'center',
            justifyContent: 'flex-end',
            height: 100,
            pr: 3
          }}>
          <Controller
            name="providerName"
            control={control}
            render={({ field: { value, onChange } }) => (
              <ProviderName
                value={value}
                onChange={onChange}
              />
            )}
          />
          <Controller
            name="from_date"
            control={control}
            render={({ field: { value, onChange } }) => (
              <MobileDatePicker
                label="From"
                value={value && new Date(value)}
                sx={{ mr: 3 }}
                slotProps={{
                  textField: {
                    variant: 'outlined',
                    error: false
                  },
                }}
                onChange={(selectedDateValue) => onChange(selectedDateValue as string)}
              />
            )}
          />

          <Controller
            name="to_date"
            control={control}
            render={({ field: { value, onChange } }) => (
              <MobileDatePicker
                label="To"
                value={value && new Date(value)}
                sx={{ mr: 3 }}
                slotProps={{
                  textField: {
                    variant: 'outlined',
                    error: false
                  },
                }}
                onChange={(selectedDateValue) => onChange(selectedDateValue as string)}
              />
            )}
          />
          <LoadingButton
            type='submit'
            loading={isLoading}
            variant={"contained"}
            sx={{ ml: 3, height: 50 }}
          >GENERATE</LoadingButton>
        </Box>
      </form>
    </LocalizationProvider>
  );
}

export default FilterSection;
