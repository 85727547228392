import { Button, IconButton, InputAdornment } from "@mui/material";

import { UsersToolbarProps } from "@src/lib/types/users";

import Config from '@src/utils/api/config'

import Iconify from "@src/components/iconify";
import ButtonExport from "@src/components/buttons/button-export";

import { StyledRoot, StyledSearch, StyledWrap } from "@src/sections/@dashboard/styles.css";

import { useRedeemTransactionsContext } from "./provider";
import { useRedeemTransactionsService } from "./hooks";

const { SITE } = Config;

const Toolbar = ({ isLoading }: UsersToolbarProps) => {
    const { state, handleSearch, handleToggleFilterSidebar } = useRedeemTransactionsContext();
    const { onRefreshRedeemTransactionsList, onExportRedeemTransactionsList } = useRedeemTransactionsService();

    const { search } = state ?? {}

    return (
        <StyledRoot>
            <StyledSearch
                disabled={isLoading}
                value={search}
                onChange={(event) => handleSearch(event.target.value)}
                placeholder="Search by user id..."
                startAdornment={
                    <InputAdornment position="start">
                        <Iconify
                            icon="eva:search-fill"
                            sx={{
                                color: 'text.disabled',
                                width: 20,
                                height: 20
                            }}
                        />
                    </InputAdornment>
                }
            />

            <StyledWrap>
                {SITE === 'palmsplay' && (
                    <Button
                        disableRipple
                        color="inherit"
                        endIcon={<Iconify icon="ic:round-filter-list" />}
                        onClick={() => handleToggleFilterSidebar(true)}
                    >
                        Filters&nbsp;
                    </Button>
                )}
                <IconButton aria-label="delete" color="primary" onClick={onRefreshRedeemTransactionsList}>
                    <Iconify icon={'mdi:refresh'} width={30} />
                </IconButton>
                <ButtonExport
                    sx={{ marginLeft: 5 }}
                    onClick={() => onExportRedeemTransactionsList.mutate()}
                    loading={onExportRedeemTransactionsList.isPending}
                />
            </StyledWrap>
        </StyledRoot>
    )
}

export default Toolbar