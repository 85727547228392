import axiosClient from "@src/utils/axios-client";

export const getCoinTransactionBetStatsAPI = async () => {
    try {
        const result = await axiosClient.get("/coin-transactions/bet/stats");
        return result.data;
    } catch (error: any) {
        throw error.response.data.error;
    }
};
