import { MenuItem, Popover } from '@mui/material';
import { useGamesContext } from './provider';

function ActionMenu() {
  const { state, handleCloseMenu, handleOpenEditDetails } = useGamesContext();
  const { open, anchorEl } = state || {};

  return (
    <Popover
      open={Boolean(open)}
      anchorEl={anchorEl}
      onClose={handleCloseMenu}
      anchorOrigin={{ vertical: 'top', horizontal: 'left' }}
      transformOrigin={{ vertical: 'top', horizontal: 'right' }}
      PaperProps={{
        sx: {
          p: 1,
          width: 160,
          '& .MuiMenuItem-root': {
            px: 1,
            typography: 'body2',
            borderRadius: 0.75,
          },
        },
      }}
    >
      <MenuItem onClick={handleOpenEditDetails}>
        Edit
      </MenuItem>
    </Popover>
  )
}

export default ActionMenu;