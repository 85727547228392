import { Access } from "./types/access-control";

const developAccess: Access = {
    viewPartnerOption: {
        fortune8: true,
        gamezone: true,
        futurePlay: true,
    },
    dashboard: {
        allowViewPrograms: true,
        allowViewTotalGames: true,
        allowViewTotalUsers: true,
        allowViewTotalNewUsersMonthly: true,
        allowViewTotalNumberOfPurchases: true,
        allowViewMostPopularGame: true,
        allowViewEngagementGraph: true,
    },
    games: {
        allowViewList: true,
        allowDataDisplay: true,
        allowViewGameInfo: true,
        allowUpdateGameInfo: true,
        allowUpdateGameThumbnail: true,
        allowExportGameData: true,
        allowUpdateTierLevel: true
    },
    users: {
        allowViewList: true,
        allowViewMore: true, // purchase, game, and coin histories
        allowUpdateUserDetails: true,
        allowUpdateUserBalance: true,
        allowUpdateTierLevel: true,
        allowDataDisplay: true, // widgets (total # of )
        allowDemoGraphs: true,
        allowExportUserData: true,
        allowCreateUser: true,
    },
    mostPlayed: {
        allowViewList: false,
    },
    purchases: {
        allowViewList: true,
        allowExportPurchaseData: true
    },
    platformTools: {
        allowQuickViewData: true,
        allowMissions: true,
        allowMails: true,
    },
    controlPanel: {
        allowInviteUser: true,
        allowViewTeam: true,
        allowUpdateTeam: true,
        allowCreatePartner: true,
        allowGenerateLicenseKey: true,
        allowViewPartnersList: true,
        allowViewLicenseKeys: true,
    },
    settings: {
        allowViewDetails: true,
        allowUpdateBusinessDetails: true,
        allowUpdateLicenseKey: true,
        allowViewTermsAndConditions: true,
        allowViewPortalGuide: true,
    },
    portalUserProfile: {
        allowUpdateDetails: true,
    },
    revenueShare: {
        allowView: true,
    },
    redeemTransactions: {
        allowView: true,
    },
};

const ownerPartnerAccess: Access = {
    viewPartnerOption: {
        fortune8: false,
        gamezone: false,
        futurePlay: false,
    },
    dashboard: {
        allowViewPrograms: true,
        allowViewTotalGames: true,
        allowViewTotalUsers: true,
        allowViewTotalNewUsersMonthly: true,
        allowViewTotalNumberOfPurchases: true,
        allowViewMostPopularGame: true,
        allowViewEngagementGraph: true,
    },
    games: {
        allowViewList: true,
        allowDataDisplay: true,
        allowViewGameInfo: true,
        allowUpdateGameInfo: true,
        allowUpdateGameThumbnail: false,
        allowExportGameData: true,
        allowUpdateTierLevel: true
    },
    users: {
        allowViewList: true,
        allowViewMore: true, // purchase, game, and coin histories
        allowUpdateUserDetails: true,
        allowUpdateUserBalance: true,
        allowUpdateTierLevel: true,
        allowDataDisplay: true, // widgets (total # of )
        allowDemoGraphs: true,
        allowExportUserData: true,
        allowCreateUser: false,
    },
    mostPlayed: {
        allowViewList: false,
    },
    purchases: {
        allowViewList: true,
        allowExportPurchaseData: true
    },
    platformTools: {
        allowQuickViewData: true,
        allowMissions: true,
        allowMails: true,
    },
    controlPanel: {
        allowInviteUser: true,
        allowViewTeam: true,
        allowUpdateTeam: true,
        allowCreatePartner: false,
        allowGenerateLicenseKey: false,
        allowViewPartnersList: false,
        allowViewLicenseKeys: false,
    },
    settings: {
        allowViewDetails: true,
        allowUpdateBusinessDetails: true,
        allowUpdateLicenseKey: true,
        allowViewTermsAndConditions: false,
        allowViewPortalGuide: true,
    },
    portalUserProfile: {
        allowUpdateDetails: true,
    },
    revenueShare: {
        allowView: false,
    },
    redeemTransactions: {
        allowView: false,
    },
};

const adminAccess: Access = {
    viewPartnerOption: {
        fortune8: false,
        gamezone: false,
        futurePlay: false,
    },
    dashboard: {
        allowViewPrograms: true,
        allowViewTotalGames: true,
        allowViewTotalUsers: true,
        allowViewTotalNewUsersMonthly: true,
        allowViewTotalNumberOfPurchases: true,
        allowViewMostPopularGame: true,
        allowViewEngagementGraph: true,
    },
    games: {
        allowViewList: true,
        allowDataDisplay: true,
        allowViewGameInfo: true,
        allowUpdateGameInfo: true,
        allowUpdateGameThumbnail: false,
        allowExportGameData: true,
        allowUpdateTierLevel: true
    },
    users: {
        allowViewList: true,
        allowViewMore: true, // purchase, game, and coin histories
        allowUpdateUserDetails: true,
        allowUpdateUserBalance: true,
        allowUpdateTierLevel: true,
        allowDataDisplay: true, // widgets (total # of )
        allowDemoGraphs: true,
        allowExportUserData: true,
        allowCreateUser: false,
    },
    mostPlayed: {
        allowViewList: false,
    },
    purchases: {
        allowViewList: true,
        allowExportPurchaseData: true
    },
    platformTools: {
        allowQuickViewData: true,
        allowMissions: true,
        allowMails: true,
    },
    controlPanel: {
        allowInviteUser: true,
        allowViewTeam: true,
        allowUpdateTeam: true,
        allowCreatePartner: false,
        allowGenerateLicenseKey: false,
        allowViewPartnersList: false,
        allowViewLicenseKeys: false,
    },
    settings: {
        allowViewDetails: true,
        allowUpdateBusinessDetails: false,
        allowUpdateLicenseKey: false,
        allowViewTermsAndConditions: false,
        allowViewPortalGuide: true,
    },
    portalUserProfile: {
        allowUpdateDetails: true,
    },
    revenueShare: {
        allowView: false,
    },
    redeemTransactions: {
        allowView: false,
    },
};

const generalAccess: Access = {
    viewPartnerOption: {
        fortune8: false,
        gamezone: false,
        futurePlay: false,
    },
    dashboard: {
        allowViewPrograms: true,
        allowViewTotalGames: true,
        allowViewTotalUsers: true,
        allowViewTotalNewUsersMonthly: true,
        allowViewTotalNumberOfPurchases: true,
        allowViewMostPopularGame: true,
        allowViewEngagementGraph: true,
    },
    games: {
        allowViewList: true,
        allowDataDisplay: true,
        allowViewGameInfo: false,
        allowUpdateGameInfo: false,
        allowUpdateGameThumbnail: false,
        allowExportGameData: false,
        allowUpdateTierLevel: false
    },
    users: {
        allowViewList: true,
        allowViewMore: false, // purchase, game, and coin histories
        allowUpdateUserDetails: false,
        allowUpdateUserBalance: false,
        allowUpdateTierLevel: false,
        allowDataDisplay: true, // widgets (total # of )
        allowDemoGraphs: true,
        allowExportUserData: false,
        allowCreateUser: false,
    },
    mostPlayed: {
        allowViewList: false,
    },
    purchases: {
        allowViewList: false,
        allowExportPurchaseData: false
    },
    platformTools: {
        allowQuickViewData: true,
        allowMissions: false,
        allowMails: false,
    },
    controlPanel: {
        allowInviteUser: false,
        allowViewTeam: false,
        allowUpdateTeam: false,
        allowCreatePartner: false,
        allowGenerateLicenseKey: false,
        allowViewPartnersList: false,
        allowViewLicenseKeys: false,
    },
    settings: {
        allowViewDetails: true,
        allowUpdateBusinessDetails: false,
        allowUpdateLicenseKey: false,
        allowViewTermsAndConditions: false,
        allowViewPortalGuide: true,
    },
    portalUserProfile: {
        allowUpdateDetails: true,
    },
    revenueShare: {
        allowView: false,
    },
    redeemTransactions: {
        allowView: false,
    },
};

const gameProviderAccess: Access = {
    viewPartnerOption: {
        fortune8: false,
        gamezone: false,
        futurePlay: false,
    },
    dashboard: {
        allowViewPrograms: false,
        allowViewTotalGames: false,
        allowViewTotalUsers: false,
        allowViewTotalNewUsersMonthly: false,
        allowViewTotalNumberOfPurchases: false,
        allowViewMostPopularGame: false,
        allowViewEngagementGraph: false,
    },
    games: {
        allowViewList: true,
        allowDataDisplay: false,
        allowViewGameInfo: false,
        allowUpdateGameInfo: false,
        allowUpdateGameThumbnail: false,
        allowExportGameData: false,
        allowUpdateTierLevel: false
    },
    users: {
        allowViewList: false,
        allowViewMore: false, // purchase, game, and coin histories
        allowUpdateUserDetails: false,
        allowUpdateUserBalance: false,
        allowUpdateTierLevel: false,
        allowDataDisplay: false, // widgets (total # of )
        allowDemoGraphs: false,
        allowExportUserData: false,
        allowCreateUser: false,
    },
    mostPlayed: {
        allowViewList: false,
    },
    purchases: {
        allowViewList: false,
        allowExportPurchaseData: false
    },
    platformTools: {
        allowQuickViewData: false,
        allowMissions: false,
        allowMails: false,
    },
    controlPanel: {
        allowInviteUser: false,
        allowViewTeam: false,
        allowUpdateTeam: false,
        allowCreatePartner: false,
        allowGenerateLicenseKey: false,
        allowViewPartnersList: false,
        allowViewLicenseKeys: false,
    },
    settings: {
        allowViewDetails: false,
        allowUpdateBusinessDetails: false,
        allowUpdateLicenseKey: false,
        allowViewTermsAndConditions: false,
        allowViewPortalGuide: false,
    },
    portalUserProfile: {
        allowUpdateDetails: false,
    },
    revenueShare: {
        allowView: true,
    },
    redeemTransactions: {
        allowView: false,
    },
};

const accessControl: { [key: number]: Access } = {
    2: developAccess,
    3: ownerPartnerAccess,
    4: adminAccess,
    5: generalAccess,
    6: gameProviderAccess
};

export default accessControl;