import { Helmet } from 'react-helmet-async';
import { Container, Stack, Typography } from '@mui/material';
import sections from '@src/lib/data/portal-guide.json';
import TableOfContents from './table-of-contents';

type Props = {}

export default function PortalGuide({ }: Props) {
    return (
        <>
            <Helmet>
                <title> Portal Guide </title>
            </Helmet>
            <Container>
                <Stack direction="row" alignItems="center" justifyContent="space-between" mt={5}>
                    <Typography variant="h4" gutterBottom>
                        Portal Guide
                    </Typography>
                </Stack>
                <TableOfContents />
                {sections.map((section, index) => (
                    <div id={`section-${index}`} key={index}>
                        <h4>{section.title}</h4>
                        <ol>
                            {section.steps.map((step, stepIndex) => (
                                <li key={stepIndex}>{step}</li>
                            ))}
                        </ol>
                        {section.note && (
                            <div>
                                <h5>Note</h5>
                                <ul>
                                    {section.note.map((note, noteIndex) => (
                                        <li key={noteIndex}>{note}</li>
                                    ))}
                                </ul>
                            </div>
                        )}
                    </div>
                ))}
            </Container>
        </>
    )
}