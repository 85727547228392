import { useQuery } from "@tanstack/react-query";

import { generateProgramsAPI } from "@src/api/programs";

import { GenerateProgramsAPIParams, UseProgramsServiceOperators } from "@src/lib/types/programs";
import { useProgramsContext } from "./provider";

export const useProgramsService = (): Readonly<UseProgramsServiceOperators> => {
    const { state } = useProgramsContext();
    const { from_date, to_date, providerName = 'Evo' } = state || {};

    const generateProgramsQueryKey = [
        'generate-programs',
        {
            from_date,
            to_date,
            providerName,
        }
    ];

    const { data, isLoading } = useQuery({
        queryKey: generateProgramsQueryKey,
        queryFn: async () => {
            const reqParams: GenerateProgramsAPIParams = {
                providerName,
            };

            if (from_date) reqParams.from_date = from_date;
            if (to_date) reqParams.to_date = to_date;

            const result = await generateProgramsAPI(reqParams);
            return result;
        },
    });

    return {
        data,
        result: data?.data || {},
        isLoading,
    }
};