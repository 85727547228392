import { GameHistoryContextStateType, GameHistoryContextValueType, GameHistoryProviderProps } from '@src/lib/types/game-history';
import { useInputHelper } from '@src/utils/inputs-helper';
import { ChangeEvent, createContext, useContext } from 'react';

const initialState: GameHistoryContextStateType = {
  page: 0,
  order: 'desc',
  orderBy: 'CreatedDate',
  rowsPerPage: 10,
}

export const GameHistoryContext = createContext<GameHistoryContextValueType>({} as GameHistoryContextValueType);

const GameHistoryProvider = ({ children }: GameHistoryProviderProps) => {
  const { state, onDispatch } = useInputHelper(initialState);

  const handlePageChange = (event: unknown, newPage: number) => {
    onDispatch('page')(newPage);
  }

  const handleRequestSort = (property: string) => {
    const isAsc = state.orderBy === property && state.order === 'asc';
    onDispatch('order')(isAsc ? 'desc' : 'asc');
    onDispatch('orderBy')(property);
  };

  const handleRowsPerPageChange = (event: ChangeEvent<HTMLInputElement>) => {
    onDispatch('rowsPerPage')(event.target.value);
  }

  const value = {
    state,
    onDispatch,
    handlePageChange,
    handleRequestSort,
    handleRowsPerPageChange,
  };

  return (
    <GameHistoryContext.Provider value={value}>
      {children}
    </GameHistoryContext.Provider>
  );
};

export const useGameHistoryContext = () => {
  return useContext(GameHistoryContext);
}

export default GameHistoryProvider;
