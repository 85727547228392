import React from 'react'
import sections from '@src/lib/data/portal-guide.json';
import { scrollToSection } from '@src/utils/scroll-to-section';

const TableOfContents = () => {
    const handleScroll = (event: any, id: string) => {
        event.preventDefault();
        const offset = 170; // Adjust this value to your header height
        scrollToSection(id, offset);
    };

    return (
        <div>
            <h2>Table of Contents</h2>
            <ul>
                {sections.map((section, index) => (
                    <li key={index}>
                        <a href="/" onClick={(event) => handleScroll(event, `section-${index}`)}>
                            {section.title}
                        </a>
                    </li>
                ))}
            </ul>
        </div>
    )
}

export default TableOfContents