import { Box, Typography } from '@mui/material';
import _sortBy from 'lodash/sortBy';

import useCoinTransactionBetStats from '@src/hooks/useCoinTransactionBetStatsService';
import {
	ResponsiveContainer,
	Area,
	CartesianGrid,
	ComposedChart,
	Tooltip,
	XAxis,
	YAxis,
	Bar,
	Legend
} from 'recharts';
import { useMemo } from 'react';

const TotalNumberOfPurchase = () => {
	const { data } = useCoinTransactionBetStats();

	const sortedData = useMemo(() => {
			return _sortBy(data?.days || [], 'date');
	}, [data]);

	return (
		<Box mt={5}>
			<Typography variant="h5" sx={{ mt: 5, mb: 2 }}>
				Total Number of Purchases
			</Typography>
			<Box p={"20px"} borderRadius={"25px"} display={"flex"}
				width={"100%"}
				height={350}
				bgcolor={"white"}>
				<ResponsiveContainer width="100%" height="100%">
					<ComposedChart
						// width={400}
						// height={350}
						data={sortedData}
						margin={{
								top: 50,
								right: 10,
								bottom: 20,
								left: 10,
						}}>
						<CartesianGrid stroke="#f5f5f5" />
						<XAxis
							dataKey="date"
							angle={-45}
							textAnchor="end"
							height={70}
							dy={10}
						/>
						<YAxis
							label={{ value: 'Total number of purchases', angle: 0, position: 'insideLeft', dy: -120, dx: 0 }}
						/>
						<Tooltip />
						<Bar dataKey="numBetUsers" barSize={20} fill="#413ea0" />
					</ComposedChart>
				</ResponsiveContainer>
				
			</Box>
		</Box>
	)
}

export default TotalNumberOfPurchase