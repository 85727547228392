import { getCoinTransactionBetStatsAPI } from "@src/api/coin-transactions";
import { useQuery } from "@tanstack/react-query";

const useCoinTransactionBetStats = () => {
    const { data, isLoading } = useQuery({
        queryKey: ["coin-transaction-bet-stats"],
        queryFn: async () => {
            const result = await getCoinTransactionBetStatsAPI();
            return result;
        },
    });

    return {
        data,
        isLoading,
    };
};

export default useCoinTransactionBetStats;