import { Box, Typography } from '@mui/material';
import _sortBy from 'lodash/sortBy';

import useCoinTransactionBetStats from '@src/hooks/useCoinTransactionBetStatsService';
import {
	ResponsiveContainer,
	Area,
	CartesianGrid,
	ComposedChart,
	Tooltip,
	XAxis,
	YAxis
} from 'recharts';
import { useMemo } from 'react';

const SubscriptionNumber = () => {
	const { data } = useCoinTransactionBetStats();

	const sortedData = useMemo(() => {
			return _sortBy(data?.days || [], 'date');
	}, [data]);

	return (
		<Box mt={5}>
			<Typography variant="h5" sx={{ mt: 5, mb: 2 }}>
			Number of Subscriptions
			</Typography>
			<Box p={"20px"} borderRadius={"25px"} display={"flex"}
				width={"100%"}
				height={350}
				bgcolor={"white"}>
				<ResponsiveContainer width="100%" height="100%">
					<ComposedChart
						// width={400}
						// height={350}
						data={sortedData}
						margin={{
								top: 50,
								right: 10,
								bottom: 20,
								left: 10,
						}}>
						<CartesianGrid stroke="#f5f5f5" />
						<XAxis
							dataKey="date"
							angle={-45}
							textAnchor="end"
							height={70}
							dy={10}
						/>
						<YAxis
							label={{ 
								value: 'Total number of subscriptions', 
								angle: 0, position: 'insideLeft', 
								dy: -120, dx: 0 
							}}
						/>
						<Tooltip />
						<Area type="monotone" dataKey="numBetUsers" fill="#8884d8" stroke="#8884d8" />
					</ComposedChart>
				</ResponsiveContainer>
				
			</Box>
		</Box>
	)
}

export default SubscriptionNumber